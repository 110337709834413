@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.montserrat-<uniquifier > {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
}

button {
  border: 0;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: 300ms;
}

button:hover {
  filter: saturate(130%);
}

.title {
  font-size: 32px;

  @media (min-width: 760px) {
    font-size: 48px;
  }
}

h1,
h2 {
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: "Montserrat", sans-serif;
}

p,
li, span {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

li::marker {
  content: "> ";
}

ul {
  padding: 0;
}

.map {
  width: 300px;
  height: 350px;

  @media (min-width: 760px) {
    width: 600px;
    height: 450px;
  }
}

a {
  text-decoration: none;
  color: unset;
}
